/********************************
	 THREE BY SLIDER
	*********************************/
jQuery(document).ready(function ($) {
  // var $window = $(window),
  //   flexslider = {
  //     vars: {},
  //   };

  // function getGridSize() {
  //   return window.innerWidth < 900 ? 1 : window.innerWidth < 1200 ? 2 : 3;
  // }
  $(".three-by-slider").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 5000, // Adjust the speed as needed
    cssEase: "linear",
    pauseOnHover: false,
    prevArrow: `<div class="three-by-slider-custom-prev">
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="26" viewBox="0 0 34 26"
        fill="none">
        <path opacity="0.4" d="M33 13L1 13M1 13L13 25M1 13L13 0.999998" stroke="#565656"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</div>`,
    nextArrow: `<div class="three-by-slider-custom-next">
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="26" viewBox="0 0 34 26"
        fill="none">
        <path d="M1 13H33M33 13L21 1M33 13L21 25" stroke="#004078" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</div>`,
    responsive: [
      {
        breakpoint: 768, // Breakpoint for mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true, // Show dots only on mobile
        },
        
      },
      {
        breakpoint: 1024, // Breakpoint for mobile devices
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          dots: true, // Show dots only on mobile
        },
        
      },
    ],
  });
  

  // $window.resize(function () {
  //   var gridSize = getGridSize();
  //   flexslider.vars.minItems = gridSize;
  //   flexslider.vars.maxItems = gridSize;
  //   $(".three-by-slider .flexslider").flexslider(
  //     "option",
  //     "controlNav",
  //     window.innerWidth < 900
  //   );
  // });

  // $(".three-by-slider").each(function () {
  //   var $thisContainer = $(this);

  //   $thisContainer.find(".slider-custom-prev").click(function () {
  //     $thisContainer.find(".flexslider").flexslider("prev");
  //   });

  //   $thisContainer.find(".slider-custom-next").click(function () {
  //     $thisContainer.find(".flexslider").flexslider("next");
  //   });
  // });
});
